import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PageIntro from "../components/PageIntro"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <section className="page-header">
        <div className="container">
          <div className="inner-container">
            <div className="columns">
              <div className="column column-6">
                <PageIntro 
                  heading={"Error 404"}
                  subheading={"Page was unable to load."}
                />
                <p className="large" style={{marginTop: "1.5rem"}}>
                  If this seems unusual or is affecting a page you’re interested in accessing, please <Link to="/contact/">contact us here</Link>.
                </p>
              </div>
              <div className="column content-b" />
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  </Layout>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: rgba(53, 163, 162, 0.05);

  .page-header {
    padding: 2.5rem 0;

    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0;
    }

    .content-b {
      .contact-us-form-container {
        margin-top: 1.5rem;
       
        @media (min-width: ${({ theme }) => theme.desktop}) {
          max-width: 382px;
          float: right;
          margin-top: 0;
        }
      }
    }
  }
`

export default NotFoundPage
